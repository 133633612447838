<template>
  <AppBar>
    <div class="create">
      <h6>基础信息</h6>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="11">
          <span>项目名称</span>
          <div>
            <el-input
              size="medium"
              v-model="title"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>项目难度</span>
          <div>
            <el-select size="medium" v-model="level_id" placeholder="请选择">
              <el-option
                v-for="item in difficultyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="24" class="radio">
          <span>项目类别</span>
          <div>
            <el-radio-group v-model="type_id">
              <el-radio :label="1">标准项目</el-radio>
              <el-radio :label="2">短期项目</el-radio>
              <el-radio :label="3">插画视频项目</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="24">
          <span>项目注释</span>
          <div>
            <el-input
              type="textarea"
              :rows="4"
              resize="none"
              placeholder="请输入内容"
              v-model="descr"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="24" class="prepend">
          <span>相关链接</span>
          <div>
            <el-input v-model="alink">
              <template slot="prepend">http://</template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="24" class="upload">
          <span>上传资料附件</span>
          <div>
            <!-- <el-upload
              action="//ghf.site.yscase.com/api/v1.index/uploadFile"
              list-type="picture-card"
              :headers="token"
              :before-upload="isType"
              :on-success="success"
            >
              <i slot="default" class="el-icon-plus"></i>
              <template #file="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="imgsrc(file)"
                />
              </template>
            </el-upload> -->
            <el-upload
              action="//ghf.site.yscase.com/api/v1.index/uploadFile"
              list-type="picture-card"
              :headers="token"
              :on-success="success"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <template #file="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="imgsrc(file)"
                />
              </template>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
      <h6>客户信息</h6>
      <el-divider></el-divider>
      <el-row>
        <el-col
          class="logoBox"
          :span="24"
          v-show="$store.state.personal.role_id !== 2"
        >
          <span>客户LOGO</span>
          <div>
            <el-upload
              class="avatar-uploader"
              action="//ghf.site.yscase.com/api/v1.index/uploadPic"
              :headers="token"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="customer.thumb"
                :src="'//ghf.site.yscase.com' + customer.thumb"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="11">
          <span>客户名称</span>
          <div>
            <el-input
              size="medium"
              v-model="customer.name"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>已有客户关联</span>
          <div>
            <!-- @change="change" -->

            <el-select
              @visible-change="select(1, $event)"
              size="medium"
              v-model="customer_id"
              placeholder="请选择"
              clearable
              filterable
            >
              <el-option
                v-for="item in relevanceList"
                :key="item.customer_id"
                :label="item.name"
                :value="item.customer_id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="24">
          <span>客户地址</span>
          <div>
            <el-input
              size="medium"
              v-model="customer.address"
              placeholder="请输入客户详细地址"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <span>联系人</span>
          <div>
            <el-input
              size="medium"
              v-model="customer.lxr"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>联系方式</span>
          <div>
            <el-input
              size="medium"
              v-model="customer.tel"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="24">
          <span>客户备注</span>
          <div>
            <el-input
              type="textarea"
              :rows="4"
              resize="none"
              placeholder="请输入内容"
              v-model="customer.note"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <h6>人员设置</h6>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="11">
          <span>PM人员</span>
          <div>
            <el-select
              @visible-change="select(2, $event)"
              size="medium"
              v-model="id_pm"
              placeholder="请选择"
              multiple
            >
              <el-option
                v-for="item in managerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>主美</span>
          <div>
            <el-select
              :disabled="inpDisa()"
              @visible-change="select(3, $event)"
              size="medium"
              v-model="id_lead"
              placeholder="请选择"
            >
              <el-option
                v-for="item in leadArtistList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <!-- <el-col :span="11">
          <span>组长</span>
          <div>
            <el-select
              disabled
              @visible-change="select(4, $event)"
              size="medium"
              v-model="id_headman"
              placeholder="请选择"
            >
              <el-option
                v-for="item in groupLeaderList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col> -->
        <el-col :span="11">
          <span>组长</span>
          <div>
            <el-select
              :disabled="inpDisa()"
              @visible-change="select(4, $event)"
              size="medium"
              v-model="id_headman"
              placeholder="请选择"
            >
              <el-option
                v-for="item in groupLeaderList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <!-- <el-col :span="11">
          <span>画师</span>
          <div>
            <el-select
              disabled
              @visible-change="select(5, $event)"
              size="medium"
              v-model="id_workers"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in painterList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col> -->
        <el-col :span="11">
          <span>画师</span>
          <div>
            <el-select
              :disabled="inpDisa()"
              @visible-change="select(5, $event)"
              size="medium"
              v-model="id_workers"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in painterList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <span>商务</span>
          <div>
            <el-select
              @visible-change="select(6, $event)"
              size="medium"
              v-model="id_business"
              placeholder="请选择"
            >
              <el-option
                v-for="item in businessList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11" v-show="$store.state.personal.role_id !== 2">
          <span>联系人</span>
          <div>
            <el-select
              @visible-change="select(7, $event)"
              size="medium"
              v-model="id_contact"
              placeholder="请选择"
            >
              <el-option
                v-for="item in linkmanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <h6>时间设置</h6>
      <el-divider></el-divider>
      <el-row>
        <!-- <el-col :span="11">
          <span>开始时间</span>
          <div>
            <el-date-picker
              v-model="date_start"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="设置项目开始时间"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col> -->
        <el-col :span="11">
          <span>草稿时间</span>
          <div>
            <el-date-picker
              v-model="draftTime"
              type="daterange"
              size="medium"
              :disabled="progress != '' && progress > 1"
              value-format="yyyy-MM-dd"
              start-placeholder="草稿开始日期"
              end-placeholder="草稿结束日期"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>色稿时间</span>
          <div>
            <el-date-picker
              v-model="colorTime"
              type="daterange"
              size="medium"
              :disabled="progress != '' && progress > 2"
              value-format="yyyy-MM-dd"
              start-placeholder="色稿开始日期"
              end-placeholder="色稿结束日期"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <span>细化时间</span>
          <div>
            <el-date-picker
              v-model="refiningTime"
              type="daterange"
              size="medium"
              :disabled="progress != '' && progress > 3"
              value-format="yyyy-MM-dd"
              start-placeholder="细化开始日期"
              end-placeholder="细化结束日期"
              :picker-options="pickerOptions2"
            >
            </el-date-picker>
          </div>
        </el-col>
        <!-- 结束时间 -->
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>结束时间</span>
          <div>
            <el-date-picker
              v-model="date_end"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="设置项目结束时间"
              :picker-options="pickerOptions3"
              :default-value="setTime"
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <h6>状态设置</h6>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="11">
          <span>当前进度</span>
          <div>
            <el-select size="medium" v-model="progress" placeholder="请选择">
              <el-option
                v-for="item in progressList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2" style="height: 40px"></el-col>
        <el-col :span="11">
          <span>紧急状态</span>
          <div>
            <el-select size="medium" v-model="pressing" placeholder="请选择">
              <el-option
                v-for="item in pressingList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <div class="btnList">
        <el-button
          type="info"
          size="medium"
          round
          @click="saveBtn"
          v-if="$common.position[$store.state.personal.role_id - 1] != '管理员'"
          >保存</el-button
        >
        <!-- @click="previewSwitch" -->
        <el-button type="info" size="medium" round @click="browseClick"
          >浏览</el-button
        >
        <el-button type="info" size="medium" round @click="issue"
          >发布</el-button
        >
      </div>
    </div>
    <Dialog
      :dialogVisible="dialogVisible"
      :id="$route.query.id"
      :preview="true"
      @dialogVisible="previewSwitch"
      ref="sonV"
    />
  </AppBar>
</template>

<script>
import AppBar from "../components/AppBar";
import Dialog from "./audit/Dialog";
const typeList = [
  ".rar",
  ".zip",
  ".doc",
  ".docx",
  ".word",
  ".rtf",
  ".xls",
  ".xlsx",
  ".png",
  ".jpg",
  ".ppt",
  ".pptx",
  ".txt",
  ".pdf",
];
let projectData = {
  title: "",
  level_id: "",
  type_id: "",
  descr: "",
  alink: "",
  docs: "",
  customer_id: "",
  customer: {
    name: "",
    thumb: "",
    address: "",
    lxr: "",
    tel: "",
    note: "",
  },
  id_pm: "",
  id_lead: "",
  id_headman: "",
  id_workers: "",
  id_business: "",
  id_contact: "",
  // date_start: new Date(),
  date_end: "",
  date_start_draft: "",
  date_end_draft: "",
  date_start_color: "",
  date_end_color: "",
  date_start_refine: "",
  date_end_refine: "",
  state: "",
  progress: "",
  pressing: "",
};
const arr = [
  "",
  "",
  "managerList",
  "leadArtistList",
  "groupLeaderList",
  "painterList",
  "businessList",
  "linkmanList",
];
export default {
  data() {
    return {
      setma: this,
      preview: false,
      desa: true,
      // 创建项目
      title: "",
      type_id: "",
      alink: "",
      // 删除图片
      dialogImageUrl: "",
      dialogVisible: false,
      difficultyList: [
        {
          value: 4,
          label: "S级",
        },
        {
          value: 3,
          label: "A级",
        },
        {
          value: 2,
          label: "B级",
        },
        {
          value: 1,
          label: "C级",
        },
      ],
      level_id: "",
      docs: "",
      descr: "",
      customer: {
        name: "",
        thumb: "",
        address: "",
        lxr: "",
        tel: "",
        note: "",
      },
      relevanceList: [],
      customer_id: "",
      managerList: [],
      id_pm: "",
      leadArtistList: [],
      id_lead: "",
      groupLeaderList: [],
      id_headman: "",
      painterList: [],
      id_workers: [],
      businessList: [],
      id_business: "",
      linkmanList: [],
      id_contact: "",
      date_start: new Date(),
      draftTime: "",
      date_start_draft: "",
      date_end_draft: "",
      colorTime: "",
      date_start_color: "",
      date_end_color: "",
      refiningTime: "",
      date_start_refine: "",
      date_end_refine: "",
      date_end: "",
      state: "",
      progressList: [
        {
          value: 1,
          label: "草图",
        },
        {
          value: 2,
          label: "色稿中",
        },
        {
          value: 3,
          label: "细化中",
        },
        {
          value: 4,
          label: "完稿",
        },
      ],
      progress: "",
      pressingList: [
        {
          value: 3,
          label: "延期",
        },
        {
          value: 4,
          label: "暂停",
        },
      ],
      pressing: "",
      sonData: [],
      //只能选择当天以及以后的日期
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.date_start) {
            return time.getTime() < new Date(this.date_start).getTime();
          }
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          if (this.date_end_draft) {
            return (
              time.getTime() <
              new Date(this.date_end_draft.replace(/-/g, "/")).getTime()
            );
          } else if (this.date_start) {
            return time.getTime() < new Date(this.date_start).getTime();
          }
        },
      },
      // 细化时间
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.date_end_color) {
            return (
              time.getTime() <
              new Date(this.date_end_color.replace(/-/g, "/")).getTime()
            );
          } else if (this.date_start) {
            return time.getTime() < new Date(this.date_start).getTime();
          }
        },
      },
      // 结束时间
      pickerOptions3: {
        disabledDate: (time) => {
          if (this.date_end_refine) {
            return (
              time.getTime() <
              new Date(this.date_end_refine.replace(/-/g, "/")).getTime()
            );
          } else if (this.date_start) {
            return time.getTime() < new Date(this.date_start).getTime();
          }
        },
      },
    };
  },
  mounted() {
    // let addProjectMsg = JSON.parse(sessionStorage.getItem("addProjectMsg"));
    // if (addProjectMsg && addProjectMsg != {}) {
    //   for (let k in addProjectMsg) {
    //     this[k] = addProjectMsg[k];
    //   }
    // }
    // sessionStorage.setItem("addProjectMsg", "{}");
  },
  created: function () {
    const { id } = this.$route.query;
    //有数据填充
    if (id) this.fillData(id);
  },
  watch: {
    //防止在我的发布中修改内容，在点击创建新项目，出现数据不更新情况
    $route: {
      handler(n, b) {
        if (!b) return;
        let id = n.query.id;
        if (id != b.query) {
          this.$router.go(0);
        }
        // 更新数据
      },
      immediate: true,
    },
    progress(newv,oldv) {
      // 判断用户选择的阶段 清空选中时间

       if (newv != oldv && oldv) {
        // 判断用户选择的阶段 清空选中时间
        if (newv > 3) {
          this.draftTime = "";
          this.colorTime = "";
          this.refiningTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            ""
          ];
          [this.deploy.date_start_color, this.deploy.date_end_color] = ["", ""];
          [this.deploy.date_end_refine, this.deploy.date_end_refine] = ["", ""];
        } else if (newv > 2) {
          this.draftTime = "";
          this.colorTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            ""
          ];
          [this.deploy.date_start_color, this.deploy.date_end_color] = ["", ""];
        } else if (newv > 1) {
          this.draftTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            ""
          ];
        }
      }
      // if (newv > 3) {
      //   this.draftTime = "";
      //   this.colorTime = "";
      //   this.refiningTime = "";
      //   [this.date_start_draft, this.date_start_draft] = ["", ""];
      //   [this.date_start_color, this.date_end_color] = ["", ""];
      //   [this.date_end_refine, this.date_end_refine] = ["", ""];
      // } else if (newv > 2) {
      //   this.draftTime = "";
      //   this.colorTime = "";
      //   [this.date_start_draft, this.date_start_draft] = ["", ""];
      //   [this.date_start_color, this.date_end_color] = ["", ""];
      // } else if (newv > 1) {
      //   this.draftTime = "";
      //   [this.date_start_draft, this.date_start_draft] = ["", ""];
      // }
    },
    // date_start(newv, oldv) {
    //   this.date_start = newv ? newv :  "";

    //   if ((this.date_start != oldv && oldv) || this.date_startFlag) {
    //     this.draftTime = "";
    //   }
    //   this.date_startFlag = true;
    // },
    draftTime(newv,oldv) {
      [this.date_start_draft, this.date_end_draft] = newv ? newv : ["", ""];
      if((oldv && [this.date_start_draft, this.date_end_draft].toString() != oldv.toString() && this.draftTimeFlag)  || this.draftTimeFlag){
        this.colorTime = "";
        [this.date_start_color, this.date_end_color] = ["", ""];
      } 
      this.draftTimeFlag = true;
    },
    colorTime(newv,oldv) {

      [this.date_start_color, this.date_end_color] = newv ? newv : ["", ""];
      if((oldv && this.isUpdate &&[this.date_start_color, this.date_end_color].toString() != oldv.toString() &&  this.colorTimeFlag)  || this.colorTimeFlag){
       this.refiningTime = "";
        [this.date_start_refine, this.date_end_refine] = ["", ""];
      } 
      this.colorTimeFlag = true;
    },
    refiningTime(newv,oldv ) {
      [this.date_start_refine, this.date_end_refine] = newv ? newv : ["", ""];
      if((oldv && [this.date_start_refine, this.date_end_refine].toString() != oldv.toString() && this.refiningTimeFlag)  || this.refiningTimeFlag){
        this.date_end = "";
      } 
      this.refiningTimeFlag = true;
    },
    // 选择已有客户，渲染已有客户信息
    customer_id: function (newv) {
      console.log(11111, newv);
      let url = "/api/v1.customer/getOne";
      console.log(newv);
      this.$common.getInterface(
        url,
        {
          id: newv,
        },
        this,
        (res) => {
          console.log(res);
          this.customer = res.data;
        }
      );
    },
  },
  methods: {
    inpDisa(){
       let role_id = window.sessionStorage.getItem("role_id");
       if(role_id==1){
         return false
       }else{
         return true
       }
    },
    async fillData(id) {
      try {
        let { data } = await this.getProjectMsgOne(id);

        for (let k in data) {
          this[k] = k == 'id_pm' ? data[k].split(',').map(Number) : (!isNaN(data[k]) && data[k]!=0) ? Number(data[k]) : (data[k] || '');
        }
        this.draftTime = [
          data.date_start_draft || "",
          data.date_end_draft || "",
        ];
        this.colorTime = [
          data.date_start_color || "",
          data.date_end_color || "",
        ];
        this.refiningTime = [
          data.date_start_refine || "",
          data.date_end_refine || "",
        ];
        let all = [
          "managerList",
          "leadArtistList",
          "groupLeaderList",
          "painterList",
          "businessList",
          "linkmanList",
        ];
        for (let i = 2; i <= 7; i++) {
          let {
            data: { data: list },
          } = await this.getInterface(i);
          this[all[i - 2]] = list;
        }
        let {
          data: { data: info },
        } = await this.getList();
        this.relevanceList = info;
      } catch (error) {
        setmas(error, "error", this);
      }
    },
    getList() {
      return new Promise((resolve, reject) => {
        this.$common.getInterface(
          "/api/v1.customer/getList",
          {},
          this,
          (res) => {
            if (res.status == "200") {
              resolve(res);
            } else {
              reject(res.msg);
            }
          }
        );
      });
    },
    getInterface(i) {
      return new Promise((resolve, reject) => {
        this.$common.getInterface(
          "/api/v1.workers/getList",
          { role_id: i },
          this,
          (res) => {
            if (res.status == "200") {
              resolve(res);
            } else {
              reject(res.msg);
            }
          }
        );
      });
    },
    getProjectMsgOne(id) {
      return new Promise((resolve, reject) => {
        this.$common.getInterface(
          "/api/v1.projects/getOne",
          { id: id },
          this,
          (res) => {
            if (res.status == "200") {
              resolve(res);
            } else {
              reject(res.msg);
            }
          }
        );
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    previewSwitch() {
      if (this.dialogVisible) {
        this.dialogVisible = false;
        this.preview = false;
      } else {
        for (let item in projectData) {
          projectData[item] = this[item] ? this[item] : "";
        }
        projectData.customer_id = this.customer_id ? this.customer_id : 0;
        projectData.id_workers = this.id_workers.toString();
        let params = {};
        for (let k in projectData) {
          if (
            k.indexOf("refine") != -1 ||
            k.indexOf("draft") != -1 ||
            k.indexOf("color") != -1
          ) {
            if (projectData[k]) {
              params[k] = projectData[k];
            }
          } else {
            params[k] = projectData[k];
          }
        }
        sessionStorage.setItem("deploy", JSON.stringify(params));
        this.dialogVisible = true;
        this.preview = true;
      }
    },
    imgsrc(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (type === ".jpg" || type === ".png") {
        return file.url;
      } else if (type === ".rar" || type === ".zip") {
        return require("../assets/img/yasuobao.png");
      } else if (
        type === ".doc" ||
        type === ".docx" ||
        type === ".word" ||
        type === ".rtf"
      ) {
        return require("../assets/img/word.png");
      } else if (type === ".xls" || type === ".xlsx") {
        return require("../assets/img/xls.png");
      } else if (type === ".ppt" || type === ".pptx") {
        return require("../assets/img/ppt.png");
      } else if (type === ".pdf") {
        return require("../assets/img/pdf.png");
      } else if (type === ".txt") {
        return require("../assets/img/txt.png");
      }
    },
    success(response) {
      if (!this.docs) this.docs = response.data;
      else this.docs += "," + response.data;
    },
    isType(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (typeList.indexOf(type) === -1) {
        setmas("该类型的文件不能上传", "error", this);

        // this.$message.error("该类型的文件不能上传");
        return false;
      }
    },
    handleAvatarSuccess(res) {
      this.customer.thumb = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        setmas("上传的LOGO只能是 JPG/PNG 格式!", "error", this);
        // this.$message.error("上传的LOGO只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        setmas("上传的图片大小不能超过 5MB!", "error", this);

        // this.$message.error("上传的图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 主美数据
    select(ele, e) {
      // console.log(ele, e);
      if (e) {
        let url = "/api/v1.workers/getList";
        switch (ele) {
          case 1:
            if (this.relevanceList.length === 0) {
              url = "/api/v1.customer/getList";
              this.$common.postInterface(url, {}, this, (res) => {
                this.relevanceList = res.data.data;
                // for (let item of res.data.data) {
                //   this.$set(this.relevanceList, this.relevanceList.length, {
                //     value: item.customer_id,
                //     label: item.name
                //   });
                // }
              });
            }
            break;
          default:
            if (this[arr[ele]].length === 0) {
              this.$common.postInterface(url, { role_id: ele }, this, (res) => {
                this[arr[ele]] = res.data.data;
                // for (let item of res.data.data) {
                //   this.$set(this[arr[ele]], this[arr[ele]].length, {
                //     value: item.id,
                //     label: item.name
                //   });
                // }
              });
            }
            break;
        }
      }
    },
    // change(e) {
    //   if (e) {
    //     this.$common.getInterface(
    //       "/api/v1.customer/getOne",
    //       {
    //         id: e
    //       },
    //       this,
    //       res => {
    //         this.customer = res.data;
    //       }
    //     );
    //   } else {
    //     this.customer = {
    //       name: "",
    //       thumb: "",
    //       address: "",
    //       lxr: "",
    //       tel: "",
    //       note: ""
    //     };
    //   }
    // },
    saveBtn(e) {
      this.issue(e, 1);
    },
    async issue(e, zt) {
      if (this.progress < 2) {
        if (!this.date_end_draft) {
          // this.$message.error("请选择草稿时间");
          setmas("请选择草稿时间", "error", this);
          return false;
        } else if (!this.date_end_color) {
          // this.$message.error("请选择色稿时间");
          setmas("请选择色稿时间", "error", this);
          return false;
        } else if (!this.date_end_refine) {
          // this.$message.error("请选择细化时间");
          setmas("请选择细化时间", "error", this);
          return false;
        }
      } else if (this.progress < 3) {
        if (!this.date_end_color) {
          // this.$message.error("请选择色稿时间");
          setmas("请选择色稿时间", "error", this);
          return false;
        } else if (!this.date_end_refine) {
          // this.$message.error("请选择细化时间");
          setmas("请选择细化时间", "error", this);

          return false;
        }
      } else if (this.progress < 4 && !this.date_end_refine) {
        // this.$message.error("请选择细化时间");
        setmas("请选择细化时间", "error", this);

        return false;
      }
      // console.log(e, zt);
      zt = zt ? zt : 2;
      this.state = zt;
      for (let item in projectData) {
        projectData[item] = this[item] ? this[item] : "";
      }
      projectData.customer_id = this.customer_id ? this.customer_id : 0;
      projectData.id_workers = this.id_workers.toString();
      let params = {};
      for (let k in projectData) {
        if (
          k.indexOf("refine") != -1 ||
          k.indexOf("draft") != -1 ||
          k.indexOf("color") != -1
        ) {
          if (projectData[k]) {
            params[k] = projectData[k];
          }
        } else {
          params[k] = projectData[k];
        }
      }
        let { role_id }= this.$store.state.personal; //身份ID 1.管理 2.PM 3.主美 4.组长 5.画师

      /**
       * 新增PM我的发布编辑修改
       */
      const { id } = this.$route.query;
      if (id) {
        try {
          params.id = id;
          await this.getAxios("/api/v1.projects/edit", params);
          if(zt == 2){
            setmas("发布成功", "success", this);
            this.$router.replace({ name: role_id == 1 ? "Index" : 'Release' })
          }else{
            setmas("保存成功", "success", this);
          }
        } catch (error) {
          setmas(error, "warning", this);
        }
        return;
      }

      this.$common.postInterface(
        "/api/v1.projects/addNew",
        params,
        this,
        (res) => {
          if (res.status === "200") {

            // console.log(res);
            switch (zt) {
              case 1:
                // this.$message.success("保存成功");
                setmas("保存成功", "success", this);
                break;
              default:
                // this.$message.success("发布成功");
                setmas("发布成功", "success", this);
                this.$router.replace({ name: role_id == 1 ? "Index" : 'Release' });
                break;
            }
          } else {
            console.log(res.msg);
            setmas(res.msg, "warning", this);
            // this.$message.warning(res.msg);
          }
        }
      );
    },
    browseClick() {
      let data = {
        title: this.title,
        type_id: this.type_id,
        alink: this.alink,
        level_id: this.level_id,
        docs: this.docs,
        descr: this.descr,
        // 关闭弹窗
        customer_id: !this.customer_id ? false : this.customer_id,
        id_pm: this.id_pm,
        id_lead: this.id_lead,
        id_headman: this.id_headman,
        id_workers: this.id_workers,
        id_business: this.id_business,
        id_contact: this.id_contact,
        // date_start: this.date_start,
        date_start_draft: this.date_start_draft,
        date_end_draft: this.date_end_draft,
        date_start_color: this.date_start_color,
        date_end_color: this.date_end_color,
        date_start_refine: this.date_start_refine,
        date_end_refine: this.date_end_refine,
        date_end: this.date_end,
        state: this.state,
        progress: this.progress,
        pressing: this.pressing,
        refiningTime: this.refiningTime,
        daterange: this.daterange,
        draftTime: this.draftTime,
      };
      this.dialogVisible = true;
      this.$refs.sonV.sonFun(data);
    },

    getAxios(url, params) {
      return new Promise((resolve, reject) => {
        this.$common.postInterface(url, params, this, (res) => {
          if (res.status == "200") {
            resolve(res);
          } else {
            reject(res.msg);
          }
        });
      });
    },
  },
  computed: {
    setTime: function () {
      return (this.refiningTime && this.refiningTime[1]) || "";
    },
    token() {
      return { token: window.sessionStorage.getItem("token") };
    },
   
  },
  components: {
    AppBar,
    Dialog,
  },
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
<style lang="less" scoped>
@deep: ~">>>";

.create {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
}
h6 {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  padding: 0 20px;
  font-size: 20px;
  color: #f8b849;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -27px;
    width: 100%;
    height: 5px;
    background-color: #f8b849;
    border-radius: 5px;
    z-index: 2001;
  }
}

@{deep}.el-row {
  text-align: right;
  width: 90%;
  margin: 0 auto;
  .el-col {
    margin-bottom: 20px;
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    & > span {
      float: left;
      width: 72px;
      line-height: 36px;
    }
    & > div {
      padding-left: 20px;
      overflow: hidden;

      .avatar-uploader {
        text-align: left;
        .el-upload {
          background-color: #ebebeb;
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          img {
            object-fit: cover;
          }
        }
        .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 300px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }
        .avatar {
          width: 300px;
          height: 100px;
          display: block;
        }
      }
    }
    &.radio {
      text-align: left;
      line-height: 36px;
      span {
        text-align: right;
      }
    }
    &.prepend {
      span {
        line-height: 40px;
      }
      .el-input {
        input {
          border: 1px solid #dcdfe6;
          border-left: none;
        }
        .el-input-group__prepend {
          padding-right: 0;
          background-color: #fff;
        }
      }
    }
    &.upload {
      text-align: left;
      & > span {
        text-align: right;
        line-height: 80px;
      }
      .el-upload-list {
        .el-upload-list__item {
          width: 80px;
          height: 80px;
          img {
            background-color: #ebebeb;
            object-fit: scale-down;
          }
        }
      }
      .el-upload {
        width: 80px;
        height: 80px;
        line-height: 88px;
        background-color: #ebebeb;
      }
    }
  }
  .logoBox {
    span {
      line-height: 105px;
    }
  }
}
.btnList {
  text-align: center;
}
</style>
